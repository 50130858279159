import * as React from "react"
import {Link} from "gatsby"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seo"

import ConnectForm, { connectFormModes } from "../../components/shared/forms/connect-form"

import IconPillIcon from "../../images/icons/pill-icon.png"

const HpSignUpPage = ({ location }) => {
    function removeErrorStates() {
        document.getElementById("first-name").classList.remove("error_state");
        document.getElementById("last-name").classList.remove("error_state");
    }
    return (
        <>
            <HPPLayout
                location={location}
                locationHostName={location.hostname}
                jobCode="PP-AUR-US-1407 (v7.0)"
                jobCodeDate="07/23"
            >
                <Helmet>
                    <html lang="en" />
                    <link rel="canonical" href="https://www.auryxiahcp.com/hyperphosphatemia/sign-up" />
                    <body id="hpp-hcp-connect" className="hpp" />
                </Helmet>

                <Seo
                    title="Latest News Sign-up"
                    description="Talk to a sales representative, order samples, or receive additional information about AURYXIA. Read Important Safety Information and Prescribing Information on this page."
                />

                <section id="hero" className="flood-hp col-8 col-lg-6">
                    <div id="orange-cta" className="orange-cta hidden">
                        <div className="flex-cta">
                            <span className="icon-wrap"><img src={IconPillIcon} alt="" /></span>
                            <div>

                                <p><strong>Already have a clinically <br className="small-only" />appropriate patient in mind?</strong></p>
                                <p>Enroll your patient in our <br className="small-only" /><Link to="/hyperphosphatemia/free-trial">Free Trial Program</Link> for AURYXIA today!</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-row">
                        <div>
                            {/* <h1>Stay connected with AURYXIA</h1> */}

                            <ConnectForm
                                location={location}
                                mode={connectFormModes.hp}
                            />
                        </div>
                    </div>
                    <div id="orange-cta-btns" className="orange-cta orange-cta-btns hidden">
                        <p>Start your appropriate patients <br className="mobile" /> on AURYXIA</p>
                        <div className="row flex-row">
                        <div className="cta-column">
                            <Link to="/hyperphosphatemia/free-trial" data-title="Request Samples">FREE TRIAL</Link>
                        </div>
                        <div className="cta-column">
                            <Link to="/hyperphosphatemia/sign-up?request-samples=true" onClick={removeErrorStates} data-title="Request Samples">REQUEST SAMPLES</Link>
                        </div>
                        </div>
                    </div>
                </section>


            </HPPLayout>
        </>
    )
}

export default HpSignUpPage
